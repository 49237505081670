import React from "react";
import "./App.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./components/layouts/Header";
import StartPage from "./components/pages/StartPage";
import LabsPage from "./components/pages/LabsPage";
import SeoulPage from "./components/pages/SeoulPage";
import ToolkitPage from "./components/pages/ToolkitPage";
import CollaboPage from "./components/pages/CollaboPage";
import { MainProvider } from "./data/mainContext";

function App() {
  return (
    <Router>
      <MainProvider>
        <div className="App">
          <div className="container">
            {/* Sidebar */}
            <Header />
            {/* Main Content */}
            <Routes>
              <Route path="/" element={<Navigate to="/start" replace />} />
              <Route path="/start" element={<StartPage />} />
              <Route path="/collabo" element={<CollaboPage />} />
              <Route path="/labs" element={<LabsPage />} />
              <Route path="/seoul" element={<SeoulPage />} />
              <Route path="/toolkit" element={<ToolkitPage />} />
            </Routes>
          </div>
        </div>
      </MainProvider>
    </Router>
  );
}

export default App;
