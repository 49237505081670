import { createContext, ReactNode, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

export type PageType = "START" | "COLLABO" | "LABS" | "SEOUL" | "TOOLKIT";

interface MainContextProps {
  selectedPage: string;
  setSelectedPage: (page: PageType) => void;
  isMobile: boolean;
}

export const MainContext = createContext<MainContextProps | undefined>(
  undefined
);

export const MainProvider = ({ children }: { children: ReactNode }) => {
  const [selectedPage, setSelectedPage] = useState<PageType>("START");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const location = useLocation();
  const hasRun = useRef(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (hasRun.current) return; // 최초 실행 이후 무시

    hasRun.current = true;

    const path: PageType =
      (location.pathname.split("/").pop()?.toLocaleUpperCase() as PageType) ||
      "START";
    setSelectedPage(path);
  }, [location.pathname]);

  return (
    <MainContext.Provider
      value={{
        selectedPage,
        setSelectedPage,
        isMobile,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
