import "./Header.scss";
import * as Logo from "../../assets/svgs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMainContext } from "../../data/mainHooks";
import { PageType } from "../../data/mainContext";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // 메뉴 상태 관리
  const navigate = useNavigate();
  const { selectedPage, setSelectedPage, isMobile } = useMainContext();

  const pages: PageType[] = ["START", "COLLABO", "LABS", "SEOUL", "TOOLKIT"];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="logo">
        <Logo.EBTILogo style={{ cursor: "pointer" }} />
        <div className="logo__description">
          <p>Entrepreneurial</p>
          <p>Behavior</p>
          <p>Type</p>
          <p>Indicator</p>
        </div>
      </div>
      {/* 데스크탑 네비게이션 */}
      <nav className="nav">
        {pages.map((item) => (
          <div
            key={item}
            className={`nav__link ${selectedPage === item ? "active" : ""}`}
            onClick={() => {
              setSelectedPage(item);
              navigate(`/${item.toLowerCase()}`);
            }}
          >
            {item}
          </div>
        ))}
        {/* <div className="auth">                                        //로그인, 회원가입 버튼 숨김
          <a
            className="auth__link"
            style={{ borderRight: "solid 1px black" }}
            href="https://ebtilabs.kr/accounts/login/?next=/report/"
          >
            Login
          </a>
          <a className="auth__link" href="https://ebtilabs.kr/register/">
            Create account
          </a>
        </div> */}
      </nav>
      {/* 모바일 햄버거 메뉴 버튼 */}
      <div style={{ display: isMobile ? "flex" : "none" }}>
        {/* <button className="menu-button" onClick={toggleMenu}>
          <Logo.User />
        </button> */}
        <button className="menu-button" onClick={toggleMenu}>
          {isMenuOpen ? <Logo.Menu /> : <Logo.Menu />}
        </button>
      </div>
      {/* 모바일 네비게이션 */}
      <div className={`mobile-nav-container ${isMenuOpen ? "open" : ""}`}>
        <nav className={`mobile-nav`}>
          {pages.map((item) => (
            <div
              key={item}
              className={`mobile-nav__link ${
                selectedPage === item ? "active" : ""
              }`}
              onClick={() => {
                setSelectedPage(item);
                setIsMenuOpen(false);
                navigate(`/${item.toLowerCase()}`);
              }}
            >
              {item}
            </div>
          ))}
        </nav>
      </div>
    </header>
  );
};

export default Header;
