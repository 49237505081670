export const newSchoolPrograms = [
  {
    id: 1001,
    name: "EBTI 기업가행동유형 활용 CEO 코칭 과정",
    time: "30h",
    content: "소규모 그룹 학습",
  },
  {
    id: 1002,
    name: "EBTI 기업가행동유형 활용 TEAM 코칭 과정",
    time: "30h",
    content: "팀 기반 소규모 그룹 학습",
  },
  {
    id: 1003,
    name: "EBTI 기업가행동유형 활용 GROUP 코칭 과정",
    time: "30h",
    content: "프로젝트 기반 소규모 그룹 학습",
  },
];

export const experienceCourses = [
  {
    id: 2001,
    name: "EBTI 기업가행동유형 툴킷 소개",
    time: "2h",
    content: "활용 사례 발표(정기 세미나)",
  },
  {
    id: 2002,
    name: "EBTI 기업가행동유형 툴킷 사용자 워크숍",
    time: "5h",
    content: "교재, 검사지 제공",
  },
  {
    id: 2003,
    name: "EBTI 기업가행동유형 기반 멘토링 과정",
    time: "15h",
    content: "진단 보고서 제공",
  },
  {
    id: 2004,
    name: "EBTI 기업가행동유형 활용 컨설팅 과정",
    time: "20h",
    content: "컨설턴트 활용 협업 TOOL 개발",
  },
  {
    id: 2005,
    name: "EBTI 기업가행동유형 퍼실리테이터 과정",
    time: "30h",
    content: "워크숍 진행자 양성, 전문가 과정",
  },
];

export const leadershipPrograms = [
  {
    id: 3001,
    name: "협업 리더십 Collaborating Leadership",
    target: "핵심인재",
    size: "1:1, 1:2, 1:4",
    time: "2h, 4h, 8h",
    notes: "코칭, 멘토링",
  },
  {
    id: 3002,
    name: "기업가 리더십 Enterprising Leadership",
    target: "CEO, 임원",
    size: "1:1, 1:4",
    time: "2h, 4h, 8h",
    notes: "코칭, 멘토링",
  },
  {
    id: 3003,
    name: "창조 리더십 Creative Leadership",
    target: "신입사원",
    size: "1:1, 1:4",
    time: "2h, 4h, 8h",
    notes: "코칭, 멘토링",
  },
  {
    id: 3004,
    name: "이노베이터 DNA 발견하기",
    target: "EBTI 사용자",
    size: "1:1, 1:4",
    time: "주 1회, 2주",
    notes: "코칭, 멘토링",
  },
  {
    id: 3005,
    name: "크리에이터 GRIT 만들기",
    target: "EBTI 사용자",
    size: "1:1, 1:4",
    time: "주 1회, 2주",
    notes: "코칭, 멘토링",
  },
];

export const hrConsultingPrograms = [
  {
    id: 4001,
    name: "CEO를 위한 EBTI 코칭 서비스",
    content:
      "기업가 정체성과 미래 비전 도출, CEO 기업가 행동 중심 팀 구성 newline 핵심인재, 신입사원 중심 팀 구성 Tip",
    period: "1:1 맞춤형 (총 3회)",
  },
  {
    id: 4002,
    name: "TEAM을 위한 EBTI 진단 서비스",
    content:
      "우리 팀, 일하는 스타일은? / 우리 팀, 협업 온도는? newline 우리 팀, 협업 네트워크는? / 우리 팀에 필요한 역량은?",
    period: "1:4~6 맞춤형 (총 1회)",
  },
  {
    id: 4003,
    name: "1:1 개인 맞춤 멘토링 서비스",
    content:
      "요즘 나는 어떤 사람인가? / 요즘 나와 비슷한 사람은? newline 요즘 내가 선호하는 사람은? / 요즘 내가 협업할 사람은?",
    period: "1:1 맞춤형 (1주)",
  },
];

export const happinessLectures = [
  {
    id: 5001,
    name: "자기다움: 남과 비교하지 않고 나다운 인생을 찾는 법",
    target: "누구나",
    size: "10~20명 내외",
    time: "1.5h",
    notes: "-",
  },
  {
    id: 5002,
    name: "자기다운 일: 행복한 일, 행복한 삶을 위한 나다운 일 찾는 방법",
    target: "누구나",
    size: "10~20명 내외",
    time: "1.5h",
    notes: "-",
  },
  {
    id: 5003,
    name: "자기다운 삶: 지금부터라도 행복한 내 삶을 찾는 방법",
    target: "누구나",
    size: "10~20명 내외",
    time: "1.5h",
    notes: "-",
  },
];
