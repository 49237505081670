import "./SeoulPage.scss";
import * as Shapes from "../../assets/svgs";
import {
  experienceCourses,
  happinessLectures,
  hrConsultingPrograms,
  leadershipPrograms,
  newSchoolPrograms,
} from "../../data/tableData";
import classNames from "classnames";
import { useMainContext } from "../../data/mainHooks";

const SeoulPage = () => {
  const { isMobile } = useMainContext();
  interface LeadershipItem {
    label: string;
    description: string;
    title: string;
    colorClass: string;
  }

  const leadershipData: LeadershipItem[] = [
    {
      label: "CEO",
      description: "를 위한 기업가 리더십 함양",
      title: "EBTI Enterprising Leadership",
      colorClass: "ceo",
    },
    {
      label: "Team",
      description: "을 위한 협업 역량 개발",
      title: "EBTI Collaborating Leadership",
      colorClass: "team",
    },
    {
      label: "Entrepreneur",
      description: "를 위한 창조 혁신 역량 구축",
      title: "Innovator’s DNA & Creator’s Grit",
      colorClass: "entrepreneur",
    },
  ];
  return (
    <div className="seoul-page">
      <div className="seoul-description" style={{ margin: 0 }}>
        <section className="mission">
          <div className="mission__title-container">
            <Shapes.PinkCircle className="mission__highlight-svg" />
            <p className="mission__title">
              이비티아이는{" "}
              <span className="mission__title_bold"> EBTI SEOUL</span>
            </p>
          </div>
          <p className="mission__text">
            창조적 기업가정신 학습 커뮤니티를 조성하고 있습니다
          </p>
          <p className="mission__text_eng">
            Inspiring, The Creative City & Business!
          </p>
        </section>
        <section className="intro">
          <Shapes.MoonAndParkImg className="intro__img" />
          <div className="intro__highlight">
            <Shapes.ParkIntroTitle
              style={{
                maxWidth: isMobile ? "159px" : "232px",
              }}
            />
            <Shapes.ParkIntro
              style={{ minWidth: isMobile ? "311px" : "450px" }}
            />
          </div>
        </section>
      </div>
      <section className="graphics">
        <div className="graphics__item">
          <Shapes.SeoulTower />
          <Shapes.Gwangwha />
        </div>
        <div className="graphics__item">
          <Shapes.Museum />
          <Shapes.BlueHouse />
        </div>
      </section>
      <div className="seoul-description">
        <section className="mission">
          <div className="mission__title-container">
            <Shapes.YellowCircle className="mission__highlight-svg" />
            <p className="mission__title">
              이비티아이는
              <span className="mission__title_bold"> EBTI SEOUL</span>
            </p>
          </div>
          <div className="mission__text-container">
            <p className="mission__text">EBTI New School Program</p>
            <p className="mission__text">
              좋은 기업가와 건강한 일 학습 문화를 만듭니다
            </p>
          </div>
          <div className="mission__text-container">
            <p className="mission__text">EBTI Experience Course</p>
            <p className="mission__text">
              기업가정체성을 배양하고, 기업가행동을 경험합니다
            </p>
          </div>
        </section>
      </div>
      <div className="ebti-program">
        {/* New School Program Table */}
        <section className="ebti-program__section">
          {isMobile ? (
            <div className="ebti-program__mobile">
              <div className="ebti-program__mobile__label">
                EBTI New School Program
                <span
                  className="expert-tag"
                  style={{ color: "var(--Table-Blue, #CFE5F3)" }}
                >
                  전문가 과정
                </span>
              </div>
              <Shapes.SchoolCEO />
              <Shapes.SchoolTeam />
              <Shapes.SchoolGroup />
            </div>
          ) : (
            <table className="ebti-program__table">
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#cfe5f3" }}>구분</th>
                  <th style={{ backgroundColor: "#cfe5f3" }}>
                    EBTI New School Program (전문가 과정)
                  </th>
                  <th style={{ backgroundColor: "#cfe5f3" }}>시간</th>
                  <th style={{ backgroundColor: "#cfe5f3" }}>내용</th>
                </tr>
              </thead>
              <tbody>
                {(() => {
                  let rowSpanMap: Record<string, number> = {}; // 동일한 time 값에 대한 rowSpan 저장
                  newSchoolPrograms.forEach((program) => {
                    if (!rowSpanMap[program.time]) {
                      rowSpanMap[program.time] = newSchoolPrograms.filter(
                        (p) => p.time === program.time
                      ).length;
                    }
                  });

                  return newSchoolPrograms.map((program, index) => {
                    const rowSpan = rowSpanMap[program.time];
                    const showTimeCell =
                      index ===
                      newSchoolPrograms.findIndex(
                        (p) => p.time === program.time
                      );

                    return (
                      <tr key={program.id}>
                        <td>{index + 1}</td>
                        <td style={{ textAlign: "left" }}>{program.name}</td>
                        {showTimeCell && (
                          <td rowSpan={rowSpan}>{program.time}</td>
                        )}
                        <td style={{ textAlign: "left" }}>{program.content}</td>
                      </tr>
                    );
                  });
                })()}
              </tbody>
            </table>
          )}
        </section>

        {/* Experience Course Table */}
        <section className="ebti-program__section">
          {isMobile ? (
            <div className="ebti-program__mobile">
              <div className="ebti-program__mobile__label">
                EBTI New School Program
                <span
                  className="expert-tag"
                  style={{ color: "var(--Table-Pink, #F4C7C3)" }}
                >
                  활용자 과정
                </span>
              </div>
              <Shapes.ExperienceToolkitIntro />
              <Shapes.ExperienceToolkitWorkshop />
              <Shapes.ExperienceMentor />
              <Shapes.ExperienceConsult />
              <Shapes.ExperienceFacilitator />
            </div>
          ) : (
            <table className="ebti-program__table">
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#f4c7c3" }}>구분</th>
                  <th style={{ backgroundColor: "#f4c7c3" }}>
                    EBTI Experience Course (활용자 과정)
                  </th>
                  <th style={{ backgroundColor: "#f4c7c3" }}>시간</th>
                  <th style={{ backgroundColor: "#f4c7c3" }}>내용</th>
                </tr>
              </thead>
              <tbody>
                {experienceCourses.map((course, index) => (
                  <tr key={course.id}>
                    <td>{index + 1}</td>
                    <td style={{ textAlign: "left" }}>{course.name}</td>
                    <td>{course.time}</td>
                    <td style={{ textAlign: "left" }}>{course.content}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </section>
        <section className="ebti-program__description">
          <p>
            <div>
              <Shapes.Reference />
            </div>
            EBTI 협업 프로그램은 모듈화로 모든 참여자들의 수요 맞춤으로 진행
            가능 (등록 및 참여 비용, 1시간 기준 5만원)
          </p>
          <ul>
            <li>
              <div>
                <Shapes.Triangle />
              </div>
              툴킷 소개 : 오픈 이노베이션 형태로 활용 사례 정기 세미나 개최
            </li>
            <li>
              <div>
                <Shapes.Triangle />
              </div>
              사용자 워크숍 : 아날로그 방식, 책과 검사지를 활용하여 검사 방법론
              실습 및 체험
            </li>
            <li>
              <div>
                <Shapes.Triangle />
              </div>
              멘토링 과정 : 소상공인, 크리에이터, 메이커, 스타트업 등 멘토 활동
              시 온라인 진단 보고서 활용
            </li>
            <li>
              <div>
                <Shapes.Triangle />
              </div>
              컨설팅 과정 : 컨설턴트가 직접 개발한 도구를 EBTI와 융합하여 협업
              비즈니스 모델 개발
            </li>
            <li>
              <div>
                <Shapes.Triangle />
              </div>
              퍼실리테이터 과정 : EBTI 사용자 워크숍 진행자 전문가 과정_코칭,
              멘토링, 컨설팅 역량 보유 必
            </li>
          </ul>
        </section>
      </div>
      <div
        className="seoul-description"
        style={{
          justifyContent: "start",
        }}
      >
        <section className="mission left">
          <div className="mission__title-container">
            <Shapes.YellowCircle className="mission__highlight-svg svg-left" />
            <p className="mission__title left">
              협업 오피스{" "}
              <span className="mission__title_bold">EBTI SEOUL</span>
            </p>
          </div>
        </section>
      </div>
      <div className="ebti-program">
        <div className="leadership-container">
          {leadershipData.map((item, index) => (
            <div key={index} className="leadership-item">
              <span className="label">
                <span className={classNames("dot", item.colorClass)}></span>
                <strong>{item.label}</strong> {item.description}
              </span>
              <span className="title">{item.title}</span>
            </div>
          ))}
        </div>
        {/* EBTI Leadership Program */}
        <section className="ebti-program__section">
          {isMobile ? (
            <div className="ebti-program__mobile">
              <div className="ebti-program__mobile__label">
                EBTI Leadership Program
              </div>
              <Shapes.LeaderEnterprise />
              <Shapes.LeaderCollabo />
              <Shapes.LeaderCreative />
              <Shapes.LeaderInnovator />
              <Shapes.LeaderCreator />
            </div>
          ) : (
            <table className="ebti-program__table">
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#ddebe3" }}>구분</th>
                  <th style={{ backgroundColor: "#ddebe3" }}>
                    EBTI Leadership Program
                  </th>
                  <th style={{ backgroundColor: "#ddebe3" }}>대상</th>
                  <th style={{ backgroundColor: "#ddebe3" }}>정원</th>
                  <th style={{ backgroundColor: "#ddebe3" }}>시간</th>
                  <th style={{ backgroundColor: "#ddebe3" }}>비고</th>
                </tr>
              </thead>
              <tbody>
                {(() => {
                  let rowSpanMap: Record<string, number> = {};
                  let columns = ["target", "size", "time", "notes"];

                  leadershipPrograms.forEach((program) => {
                    columns.forEach((col) => {
                      let key = `${col}-${
                        program[col as keyof typeof program]
                      }`;
                      if (!rowSpanMap[key]) {
                        rowSpanMap[key] = leadershipPrograms.filter(
                          (p) =>
                            p[col as keyof typeof program] ===
                            program[col as keyof typeof program]
                        ).length;
                      }
                    });
                  });

                  return leadershipPrograms.map((program, index) => {
                    return (
                      <tr key={program.id}>
                        <td>{index + 1}</td>
                        <td style={{ textAlign: "left" }}>{program.name}</td>
                        {columns.map((col) => {
                          let value = program[col as keyof typeof program];
                          let key = `${col}-${value}`;
                          let showCell =
                            index ===
                            leadershipPrograms.findIndex(
                              (p) => p[col as keyof typeof p] === value
                            );
                          return showCell ? (
                            <td key={key} rowSpan={rowSpanMap[key]}>
                              {value}
                            </td>
                          ) : null;
                        })}
                      </tr>
                    );
                  });
                })()}
              </tbody>
            </table>
          )}
        </section>

        {/* EBTI HR Consulting Program */}
        <section className="ebti-program__section">
          {isMobile ? (
            <div className="ebti-program__mobile">
              <div className="ebti-program__mobile__label">
                EBTI HR Consulting Program
              </div>
              <Shapes.ConsultCEO />
              <Shapes.ConsultTeam />
              <Shapes.ConsultOne />
            </div>
          ) : (
            <table className="ebti-program__table">
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#f4e1c5" }}>구분</th>
                  <th style={{ backgroundColor: "#f4e1c5" }}>
                    EBTI HR Consulting Program
                  </th>
                  <th style={{ backgroundColor: "#f4e1c5" }}>내용</th>
                  <th style={{ backgroundColor: "#f4e1c5" }}>
                    기간 (6주 내외)
                  </th>
                </tr>
              </thead>
              <tbody>
                {(() => {
                  let rowSpanMap: Record<string, number> = {};
                  let columns = ["period"];

                  hrConsultingPrograms.forEach((program) => {
                    columns.forEach((col) => {
                      let key = `${col}-${
                        program[col as keyof typeof program]
                      }`;
                      if (!rowSpanMap[key]) {
                        rowSpanMap[key] = hrConsultingPrograms.filter(
                          (p) =>
                            p[col as keyof typeof program] ===
                            program[col as keyof typeof program]
                        ).length;
                      }
                    });
                  });

                  return hrConsultingPrograms.map((program, index) => {
                    return (
                      <tr key={program.id}>
                        <td>{index + 1}</td>
                        <td style={{ textAlign: "left" }}>{program.name}</td>
                        <td
                          style={{ textAlign: "left", whiteSpace: "pre-line" }}
                        >
                          {program.content
                            .split("newline")
                            .map((line, index) => (
                              <span key={index}>
                                {line}
                                <br />
                              </span>
                            ))}
                        </td>
                        {columns.map((col) => {
                          let value = program[col as keyof typeof program];
                          let key = `${col}-${value}`;
                          let showCell =
                            index ===
                            hrConsultingPrograms.findIndex(
                              (p) => p[col as keyof typeof p] === value
                            );
                          return showCell ? (
                            <td key={key} rowSpan={rowSpanMap[key]}>
                              {value}
                            </td>
                          ) : null;
                        })}
                      </tr>
                    );
                  });
                })()}
              </tbody>
            </table>
          )}
        </section>

        {/* EBTI Happiness Special Lecture */}
        <section className="ebti-program__section">
          {isMobile ? (
            <div className="ebti-program__mobile">
              <div className="ebti-program__mobile__label">
                EBTI Happiness Special Lecture
              </div>
              <Shapes.HappinessMe />
              <Shapes.HappinessWork />
              <Shapes.HappinessLife />
            </div>
          ) : (
            <table className="ebti-program__table">
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#e6e1f4" }}>구분</th>
                  <th style={{ backgroundColor: "#e6e1f4" }}>
                    EBTI Happiness Special Lecture
                  </th>
                  <th style={{ backgroundColor: "#e6e1f4" }}>대상</th>
                  <th style={{ backgroundColor: "#e6e1f4" }}>정원</th>
                  <th style={{ backgroundColor: "#e6e1f4" }}>시간</th>
                  <th style={{ backgroundColor: "#e6e1f4" }}>비고</th>
                </tr>
              </thead>
              <tbody>
                {(() => {
                  let rowSpanMap: Record<string, number> = {};
                  let columns = ["target", "size", "time"];

                  happinessLectures.forEach((lecture) => {
                    columns.forEach((col) => {
                      let key = `${col}-${
                        lecture[col as keyof typeof lecture]
                      }`;
                      if (!rowSpanMap[key]) {
                        rowSpanMap[key] = happinessLectures.filter(
                          (l) =>
                            l[col as keyof typeof lecture] ===
                            lecture[col as keyof typeof lecture]
                        ).length;
                      }
                    });
                  });

                  return happinessLectures.map((lecture, index) => {
                    return (
                      <tr key={lecture.id}>
                        <td>{index + 1}</td>
                        <td style={{ textAlign: "left" }}>{lecture.name}</td>
                        {columns.map((col) => {
                          let value = lecture[col as keyof typeof lecture];
                          let key = `${col}-${value}`;
                          let showCell =
                            index ===
                            happinessLectures.findIndex(
                              (l) => l[col as keyof typeof l] === value
                            );
                          return showCell ? (
                            <td key={key} rowSpan={rowSpanMap[key]}>
                              {value}
                            </td>
                          ) : null;
                        })}
                        <td>{lecture.notes}</td>
                      </tr>
                    );
                  });
                })()}
              </tbody>
            </table>
          )}
        </section>
      </div>
    </div>
  );
};

export default SeoulPage;
