import "./LabsPage.scss";
import * as Shapes from "../../assets/svgs";

const LabsPage = () => {
  return (
    <div className="labs-page pages">
      <div className="description">
        <section className="mission">
          <div className="mission__title-container">
            <Shapes.BlueCircle className="mission__highlight-svg" />
            <p className="mission__title">
              이비티아이는
              <span className="mission__title_bold"> IDENTITY</span>
            </p>
          </div>
          <p className="mission__text">
            인간의 본질을 탐구하고 새로운 미학적 경험을 함께 만들어 갑니다
          </p>
          <p className="mission__text_eng">
            We explore human nature and create new aesthetic experiences
            together
          </p>
        </section>
        <section className="intro">
          <p className="intro__highlight">
            우리는 인간의 언어적, 시각적 아이덴티티 연구로부터
          </p>
          <p className="intro__highlight">
            기업가정신을 표현하고 기업가행동을 발현할 수 있도록 돕습니다
          </p>
          <p className="intro__subtext">
            We help people express entrepreneurship and develop entrepreneurial
            <br />
            behavior through research on human verbal and visual identity
          </p>
        </section>
      </div>
      <section className="graphics">
        <div className="graphics__item">
          <Shapes.DHexagon />
        </div>
        <div className="graphics__item">
          <Shapes.IHexagon />
        </div>
        <div className="graphics__item">
          <Shapes.CHexagon />
        </div>
        <div className="graphics__item">
          <Shapes.EHexagon />
        </div>
      </section>
      <p className="graphics__description font-bold">
        [4가지 기업가 유형과 24가지 EBTI 기업가행동유형]
      </p>
      <section className="labs-info">
        <p className="labs-info__text">
          EBTI 연구 모형은 상호 유기적이고 복합적으로 통합 설계되어 실제 같은
          행동 유형이 나오더라도, 개인 환경에 따라 솔루션이 변화합니다.
          <br />
          협업 증진을 위해 24가지 기업가행동유형을 소개하고 있습니다.
        </p>
        <a className="labs-info__button" href="https://ebtilabs.kr/">
          EBTI 검사하기 →
        </a>
        <p className="labs-info__notice">
          ※ EBTI 관련 내용은 저작권, 상표권, 특허권에 의해 보호 받고 있습니다.
          활용을 원하시는 분은 문의 주세요!
        </p>
      </section>
    </div>
  );
};

export default LabsPage;
