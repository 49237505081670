import "./StartPage.scss";
import * as Shapes from "../../assets/svgs";

const StartPage = () => {
  return (
    <div className="start-page pages">
      <div className="description">
        <section className="mission">
          <div className="mission__title-container">
            <Shapes.YellowCircle className="mission__highlight-svg" />
            <p className="mission__title">
              이비티아이는{" "}
              <span className="mission__title_bold"> EBTI MISSION</span>
            </p>
          </div>
          <p className="mission__text">
            사람들의 좋은 삶을 위해 미래를 예측하고 행복을 선택하도록 돕습니다
          </p>
          <p className="mission__text_eng">
            Helping people predict the future and choose happiness for a good
            life
          </p>
        </section>
        <section className="intro">
          <p className="intro__highlight">
            세상의 모든 변화는 자기로부터 시작한다
          </p>
          <p className="intro__highlight">
            자기다움, 자기다운 일, 자기다운 삶에 대해 통찰하다
          </p>
          <p className="intro__subtext">
            All changes in the world start with yourself <br />
            Insight into one's own self, one's own work, and one's own life
          </p>
        </section>
      </div>
      <section className="graphics">
        <div className="graphics__item">
          <p>Be yourself!</p>
          <Shapes.ShapeCircle />
        </div>
        <div className="graphics__item">
          <p>Love what you do!</p>
          <Shapes.ShapeSquare />
        </div>
        <div className="graphics__item">
          <p>Be happy!</p>
          <Shapes.ShapeCircles />
        </div>
      </section>
    </div>
  );
};

export default StartPage;
