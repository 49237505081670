import "./CollaboPage.scss";
import * as Shapes from "../../assets/svgs";
import { useMainContext } from "../../data/mainHooks";

const CollaboPage = () => {
  const { isMobile } = useMainContext();
  return (
    <div className="collabo-page pages">
      <div className="description">
        <section className="mission">
          <div className="mission__title-container">
            <Shapes.GreenCircle className="mission__highlight-svg" />
            <p className="mission__title">
              이비티아이는 <span className="mission__title_bold"> EBTI BM</span>
            </p>
          </div>
          <p className="mission__text">
            EBTI 기업가행동유형 기반 융복합 협업 서비스를 제공합니다
          </p>
          <p className="mission__text_eng">
            EBTI prefers creative content and safe technology based together
          </p>
        </section>
      </div>
      <section className="graphics">
        <div className="floating-item">
          <Shapes.CollaboSquare />
        </div>
        <div className="graphics__item">
          {isMobile ? (
            <Shapes.CollaboMobileHexagon />
          ) : (
            <Shapes.CollaboHexagon />
          )}
        </div>
      </section>
    </div>
  );
};

export default CollaboPage;
