import "./ToolkitPage.scss";
import * as Shapes from "../../assets/svgs";
import { useEffect, useState } from "react";

const ToolkitPage = () => {
  const graphicsData = [
    { text: <Shapes.DiceText />, shape: <Shapes.DiceSquare /> },
    { text: <></>, shape: <></> },
    { text: <Shapes.BeText />, shape: <Shapes.BeCircle /> },
    { text: <></>, shape: <></> },
    { text: <Shapes.HappyText />, shape: <Shapes.HappyCircle /> },
    { text: <></>, shape: <></> },
  ];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const displayTime = index % 2 === 0 ? 5000 : 1000; // 짝수(5초), 홀수(1초)

    const timeout = setTimeout(() => {
      setIndex((prevIndex) => (prevIndex + 1) % graphicsData.length);
    }, displayTime);

    return () => clearTimeout(timeout); // 클린업 함수
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  return (
    <div className="toolkit-page pages">
      <div className="description">
        <section className="mission">
          <div className="mission__title-container">
            <Shapes.OrangeCircle className="mission__highlight-svg" />
            <p className="mission__title">
              이비티아이는
              <span className="mission__title_bold"> PERSONALITY</span>
            </p>
          </div>
          <p className="mission__text">
            사람들의 일과 삶에 영감을 불러 일으키고 긍정 에너지를 전파합니다
          </p>
          <p className="mission__text_eng">
            We bring inspiration and spread positive energy to people’s work and
            lives
          </p>
        </section>
      </div>
      <section className="graphics">
        {graphicsData.map((item, i) => (
          <div
            key={i}
            className={`graphics__item ${i === index ? "active" : ""}`}
          >
            {item.shape}
            {item.text}
          </div>
        ))}
      </section>
    </div>
  );
};

export default ToolkitPage;
